import React, { Component } from 'react';
import { Button } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
// import Block from './../ListDistributor';
import Grid from '@material-ui/core/Grid';
import DistributorListComponent from './../DistributorList/DistributorList';

import './DistributorMap.scss';


class DistributorMap extends Component {
  state = {
    idStateSelected: '',
    idCitySelected: '',
    states: [],
    cities: [],
    distribuidores: []
  };

  componentDidMount() {
    this.getStates();
  }

  getStates() {
    fetch('https://requiez.com/api/states')
    .then(response => response.json())
    .then(data => this.setState({
      states: data
    }));
  };

  getCities(idState) {
    fetch(`https://requiez.com/api/cities?state=${idState}`)
    .then(response => response.json())
    .then(data => this.setState({
      cities: data
    }));
  };

  getDistributor(idCity) {
    fetch(`https://requiez.com/api/distributors?city=${idCity}`)
    .then(response => response.json())
    .then(data => this.setState({
      distribuidores: data
    }));
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });

    if (event.target.name === 'idStateSelected') {
      this.getCities(event.target.value);
    } else if (event.target.name === 'idCitySelected') {
      this.getDistributor(event.target.value);
    }
  };

  render() {
    return (
      <div className="select-distributor-map mb-10">
        <Container maxWidth="lg">
          <div className="mb-10">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className="text-center">
                  <Button className="btn-map-link" variant="contained" color="primary">
                    <a href='https://www.google.com/maps/d/edit?mid=1LMDkCsGjuL6iFlVAtF_m3R_gA6Yj1z4&usp=sharing' target='_blank'  rel="noreferrer">Ver Mapa</a>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="text-center">
                  <FormControl style={{ minWidth: 140, marginRight: 25 }}>
                    <InputLabel id="state">Estado</InputLabel>
                    <Select
                      labelId="state"
                      id="state"
                      name="idStateSelected"
                      value={this.state.idStateSelected}
                      onChange={(event) => this.handleChange(event)}
                    >
                      {
                        this.state.states.map((item, index) => (
                          <MenuItem value={item.id} key={index.toString()}>{item.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>

                  { this.state.cities.length > 0 && 
                    <FormControl style={{ minWidth: 140 }}>
                      <InputLabel id="city">Municipio</InputLabel>
                      <Select
                        labelId="city"
                        id="city"
                        name="idCitySelected"
                        value={this.state.idCitySelected}
                        onChange={(event) => this.handleChange(event)}
                      >
                        {
                          this.state.cities.map((item, index) => (
                            <MenuItem value={item.id} key={index.toString()}>{item.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
          <Container maxWidth="lg">
            <DistributorListComponent
              distribuidores={this.state.distribuidores}
            />
          </Container>
        </Container>
      </div>
    );
  }
}


export default DistributorMap;