import { Component } from 'react';

import SelectProduct from './../../GeneralComponents/SelectProduct/SelectProduct';

import Container from '@material-ui/core/Container';

import './ProductsList.scss';

class ProductsList extends Component {

  render() {
    if (this.props.productsList.length > 0) {
      return (
        <div className="select-productslist mb-10">
          <Container maxWidth="lg">
            <h1 className="title-products-list">
              SELECCIONA UNO DE NUESTROS <br/> PRODUCTOS
            </h1>
            <div className="list">

            {this.props.productsList.map((value, index) => {
              let imgMin = null;
              const imgs = this.props.imgList.filter((obj) => (obj.idProduct === value.id));
              if (this.props.imgMinList) {
                imgMin = this.props.imgMinList.filter((obj) => (obj.idProduct === value.id));
              }
              const textiles = this.props.textiles.filter((obj) => (obj.idProduct === value.id));
              if (imgMin && imgMin.length > 0) {
                return <div key={index}>
                    <SelectProduct
                      key={index}
                      img={imgMin[0].img}
                      imgHover={(imgMin[1] && imgMin[1].img) ? imgMin[1].img : imgMin[0].img}
                      textiles={textiles.slice(0,5)}
                      url={value.url}
                      name={value.name}
                      code={value.code}/>
                      {/* <div>{value.id}</div> */}
                </div>
              } else if (imgs.length > 0) {
                return <div key={index}>
                    <SelectProduct
                      key={index}
                      img={imgs[0].img}
                      imgHover={(imgs[1] && imgs[1].img) ? imgs[1].img : imgs[0].img}
                      textiles={textiles.slice(0,5)}
                      url={value.url}
                      name={value.name}
                      code={value.code}/>
                      {/* <div>{value.id}</div> */}
                </div>
              } else {
                return true;
              }
            })}
            </div>
          </Container>
        </div>
      );
    }

    return true;
  }
}


export default ProductsList;