import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useHistory, Link } from "react-router-dom";

import './NavBar.scss';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();

  let search = '';
  let typingTimeout = 0;

  function handleChange(event) {
    search = event.target.value;

    if (typingTimeout) {
       clearTimeout(typingTimeout);
    }

    typingTimeout = setTimeout(function () {
      searchWords();
    }, 3000);
  }

  function handleSubmit(event) {
    if (event.key === 'Enter') {
      searchWords();
    }
  }

  function searchWords() {
    if (search !== '') {
      history.push(`/products/${search}`);
    }
  }

  return (
    <div className="container-navbar-desktop">
      <div className={classes.root}>
        <ElevationScroll {...props}>
          <AppBar position="fixed">
            <Container maxWidth="xl">
              <Toolbar>
                <span>
                  <Link to="/">
                    <img className="img-logo" src="/img/REQUIEZ_svg_v2.svg" alt="Requiez logo"/>
                  </Link>
                </span>
                <div className={classes.title}>
                  <div className="main-menu  d-none d-lg-block">
                    <nav>
                      <ul id="navigation">
                        <li>
                          <span>Producto</span>
                          <ul className="submenu">
                            <li><Link to="/categoria/linea-ejecutiva">Ejecutiva</Link></li>
                            <li><Link to="/categoria/linea-operativa">Operativa</Link></li>
                            <li><Link to="/categoria/linea-visitantes-eventos">Visitantes Y Eventos</Link></li>
                            <li><Link to="/categoria/linea-confort">Confort</Link></li>
                            <li><Link to="/categoria/linea-edu-train">Edu & Train</Link></li>
                            <li><Link to="/categoria/linea-industrial">Industrial</Link></li>
                          </ul>
                        </li>
                        <li><Link to="/distribuidores">Distribuidores</Link></li>
                        <li><Link to="/alianzas">Alianzas</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/nosotros">Nosotros</Link></li>
                        <li><Link to="/contacto">Contacto</Link></li>
                        <li>
                          <span>Descargar</span>
                          <ul className="submenu">
                            <li><a target="_blank" href="/files/Catalogo.pdf" className="btn">
                            Cátalogo 2024
                          </a></li>
                            <li><a target="_blank" href="/files/Catalogo_Motion.pdf" className="btn">
                            Motion Collection
                          </a></li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className={classes.search}>
                  <div className={classes.searchIcon} onClick={handleSubmit}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Buscar..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleChange}
                    onKeyDown={handleSubmit}
                  />
                </div>
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar/>
      </div>
    </div>
  );
}
