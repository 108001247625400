const dev = {
  URL: {
    SERVER: "http://localhost/api",
    BASE: "http://localhost"
  }
};

const prod = {
  URL: {
    SERVER: "https://requiez.com/api",
    BASE: "https://requiez.com"
  }
};

let config = '';
if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod
} else {
  config = dev
}

global.config = config;
