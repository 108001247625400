import React, { Fragment, Component } from "react";

import './Carousel.scss'

class Carousel extends Component {
  render() {
    return (
      <Fragment>
        <section className="carousell">
          <div className="carousell__container">
            <div className="carousell-item">
              <a href="https://requiez.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/img/about/requiez.png"
                  alt="Requiez"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://infinitidesignmexico.com/es" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/img/about/infiniti.png"
                  alt="Infiniti"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://labenze.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/img/about/labenze.png"
                  alt="Labenze"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://okamuramexico.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/img/about/okamura.png"
                  alt="Okamura"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://quadrifoglio.com.mx" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/img/about/quadrifoglio.png"
                  alt="Quadrifoglio"
                />
              </a>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Carousel;
