import { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './SelectProduct.scss';
import 'react-lazy-load-image-component/src/effects/opacity.css';

class SelectProduct extends Component {
  render() {
    return (
      <div className="select-product">
        <a href={`/producto/${this.props.url}`}>
          <div className="container-img">
            <div>
              {/* <LazyLoad height={400} offset={100}>
                <img className="img-main" src={this.props.img} alt={this.props.name}/>
                <img className="img-hover" src={this.props.imgHover} alt={this.props.name}/>
              </LazyLoad> */}

              <span className="img-main">
                <img src={`/img/products/${this.props.img}`} alt={this.props.name}/>
                {/* <LazyLoadImage
                  alt={this.props.name}
                  src={`/img/products/${this.props.img}`}
                  effect="opacity" /> */}
              </span>
              <span className="img-hover">
                <img src={`/img/products/${this.props.imgHover}`} alt={this.props.name}/>
                {/* <LazyLoadImage
                  alt={this.props.name}
                  src={`/img/products/${this.props.imgHover}`}
                  effect="opacity" /> */}
              </span>
            </div>
          </div>
          <div className="text-center">
            <div className="name text-center">
              {this.props.name}
            </div>
            <div className="code text-center">
              {this.props.code}
            </div>
            { this.props.textiles && this.props.textiles.length > 0 && (
              <div className="textiles text-center">
                <span>
                  Tapices: 
                </span>
                {this.props.textiles.map((value, index) => {
                  return <span className="ml-1" key={index}>
                    <img src={`/img/${value.img}`} alt=""/>
                  </span>
                })}
              </div>
            )}
          </div>
        </a>
      </div>
    );
  }
}


export default SelectProduct;