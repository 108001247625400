import { Component } from 'react';

import {
  Link
} from "react-router-dom";

import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import menuIcon from './img/002_menu_1.svg';

import './NavBar.scss';

class NavBar extends Component {

  state = {
    right: false
  }

  isHome() {
    return (this.props.location.pathname === '/');
  }

  render() {
    const toggleDrawer = (side, open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [side]: open });
    };

    const open = (side) => event => {
      this.setState({
        ...this.state,
        [side]: !this.state.right
      });
    };

    return (
      <div className="container-navbar">
        <div className="container-logo">
          <Link to="/">
            <img className="img-logo" src="/img/REQUIEZ_svg_v2.svg" alt="Requiez logo"/>
          </Link>
        </div>
        <Fab
          className={`btn-icon-menu ${(this.state.right ? 'close': '')}`}
          aria-label="add"
          onClick={open('right')}
        >
          <img
            className="icon-show-menu"
            src={menuIcon} alt="Icono de menu" />
        </Fab>
        <SwipeableDrawer
          anchor="right"
          open={this.state.right}
          className="navBar-right"
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <div className="container-links-navbar" role="presentation" onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}
          >
            <div className="container-close-menu"></div>
            <List>
              <div>
                <Link to="/">
                  <ListItem button>
                    {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                    <ListItemText primary={'Inicio'} />
                  </ListItem>
                </Link>
                <div className="split-section">
                  - Categorías -
                </div>
                <div className="container-menu-secundary">
                  <Link to="/categoria/linea-ejecutiva">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Ejecutiva'} />
                    </ListItem>
                  </Link>
                  <Link to="/categoria/linea-operativa">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Operativa'} />
                    </ListItem>
                  </Link>
                  <Link to="/categoria/linea-visitantes-eventos">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Visitantes y Eventos'} />
                    </ListItem>
                  </Link>
                  <Link to="/categoria/linea-confort">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Confort'} />
                    </ListItem>
                  </Link>
                  <Link to="/categoria/linea-edu-train">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Edu & Train'} />
                    </ListItem>
                  </Link>
                  <Link to="/categoria/linea-industrial">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'- Industrial'} />
                    </ListItem>
                  </Link>
                </div>
              </div>
            </List>
            <Link to="/alianzas">
              <ListItem button>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Alianzas'} />
              </ListItem>
            </Link>
            <Link to="/blog">
              <ListItem button>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Blog'} />
              </ListItem>
            </Link>
            <Link to="/nosotros">
              <ListItem button>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Nosotros'} />
              </ListItem>
            </Link>
            <Link to="/distribuidores">
              <ListItem button>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Distribuidores'} />
              </ListItem>
            </Link>
            <Divider />
            <List className="list-secondary-pages">
              <a href="/files/AvisoPrivacidad.pdf">Aviso de privacidad</a>
              {/* <Link to="/aviso-de-privacidad">
                <ListItem button>
                  <ListItemText className="sub-menu" primary={'Aviso de privacidad'} />
                </ListItem>
              </Link> */}
              <Link to="/contacto">
                <ListItem button>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  <ListItemText className="sub-menu" primary={'Contacto'} />
                </ListItem>
              </Link>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}


export default NavBar;