import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Container from '@material-ui/core/Container';
import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';
import Carousel from './../../PagesComponents/Carousel/Carousel';

import './About.scss';

const styles = {
  image: {
    width: '50%',
    margin: '0 auto',
    display: 'grid',
  },
  head2: {
    margin: '10px auto',
  },
}


class About extends Component {

  render(){
    return (
      <div className="about-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="inherit">
              Nosotros
            </Typography>
          </Breadcrumbs>
          }

          bannerImage = {'categoria/nosotros.png'}
        />

        <Container maxWidth="md" className="mb-10">
          <h1 className="text-center">ACERCA DE REQÜIEZ</h1>

          <p>
            Nacida en Guadalajara, Jalisco, desde 1985, Requiez colabora en la generación de experiencias, ofreciendo una gama de productos dotados de alta calidad, diseño y tecnología diseñados para ayudar a las personas a alcanzar su máximo potencial y cubrir cada necesidad de uso y funcionalidad en diversos espacios y ambientes de trabajo.
          </p>

          <p>
            Nuestro portafolio completo está integrado por cinco líneas y cientos de configuraciones para personalizar cada experiencia.
          </p>

          <p>
            Además de estar comprometidos con la calidad en los procesos y servicios, y en especial con la salud de los usuarios al ofrecer productos ergonómicos, también estamos comprometidos con el cuidado del planeta, utilizando materiales e insumos amigables con el medio ambiente, reciclados y reciclables.
          </p>

          <img className="img-note mb-10" src="/img/about2-new.png" alt="Nosotros"/>
          
          <img style={styles.image} src="/img/grupo-requiez-logo.png" alt="Logo Requiez"/>
            
          <p>
            Requiez forma parte de Grupo Requiez, empresa orgullosamente mexicana con más de 35 años de trayectoria, que ha dejado huella en la industria del mobiliario para oficinas, contract y colectividad. Desde las empresas más prominentes hasta los hogares más selectos, nuestra presencia indiscutible en las principales ciudades del país se debe a la filosofía que nos ha guiado durante todo nuestro camino: sabiduría creativa, pensamiento positivo, y tenacidad inquebrantable. Estos valores están presentes en todas las relaciones establecidas con nuestros clientes, empleados y socios comerciales.
          </p>

          
        </Container>
        <h2 style={styles.head2} className="text-center">NUESTRAS MARCAS</h2>
        <Carousel />
        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default About;