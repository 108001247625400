import React, { Component, Fragment } from 'react';
//import './Note02.scss';

const styles = {
    title: {
    display: 'flex',
    justifyContent: 'center',
    },
    image: {
    width: '100%',
    borderRadius: '10px',
    },
    par: {
      marginTop: '15px',
      marginBottom: '15px',
    },
}

class Note02 extends Component {
  render() {
    return (
      <Fragment>

          <h1 style={styles.title}>
            DÍA DE LA TIERRA
          </h1>

          <p>
            La Asamblea General de las Naciones Unidas en el año 2009 decretó el día 22 de abril como el Día Internacional de la Madre Tierra, reconociéndola como nuestro hogar. 
            Sabemos que la tierra ha tenido un gran impacto, debido a los cambios realizados por los hombres a la naturaleza, la deforestación, la contaminación de aire y suelo, entre muchos otros. Como menciona la ONU “Los ecosistemas sustentan todas las formas de vida de la Tierra. De la salud de nuestros ecosistemas depende directamente la salud de nuestro planeta y sus habitantes. Restaurar nuestros ecosistemas dañados ayudará a acabar con la pobreza, a combatir el cambio climático y prevenir una extinción masiva.”
          </p>

          <img style={styles.image} src="/img/blog/diaTierra/naturaleza3.jpg" alt="Par de manos sosteniendo una planta" srcSet=""/>

          <p style={styles.par}>
            En Grupo Requiez buscamos enfatizar y encontrar soluciones a los problemas ambientales, construyendo un medio ambiente saludable y sostenible, así como disminuir los efectos invernadero generados por los métodos de producción, colaborar en la conservación y protección de la biodiversidad junto con otras preocupaciones globales ocasionadas por la mano del hombre haciendo frente al cambio climático, y proteger la Tierra para las generaciones futuras.
          </p>

          <img style={styles.image} src="/img/blog/diaTierra/naturaleza1.jpg" alt="Hoja con gotas de agua" srcSet=""/>

          <p style={styles.par}>
            En Grupo Requiez llevamos varios años evolucionando nuestros métodos de producción y servicio, mientras creamos nuevas medidas que se irán sumando a las acciones que hemos planeado para el futuro, y que estamos seguros, ayudarán constantemente a reducir la huella que producimos en el día a día y con el tiempo, contrarrestar los efectos causados a la biodiversidad a raíz de la obtención de los denominados “recursos vírgenes”. Medidas tal como usar la mayor cantidad de elementos reciclados y reciclables, como lo son tapices y mallas, el uso de pegamentos a base de agua y en todos nuestros productos, puntos de reciclaje en todas nuestras áreas de trabajo, reducción al máximo de papel y uso exclusivo de hojas recicladas, manejo responsable de los desechos de plástico PET, entre otras acciones. 
            Aunado a esto se han entablado alianzas con proveedores, comerciantes y organizaciones con la misma visión ambiental, cada uno compartiéndonos su experiencia y nuevos métodos para contribuir a la causa sin afectar la calidad. Métodos como la fabricación de muebles de madera con materiales reciclados, acabados de melamina para escritorios, muebles de oficina o iluminación libres de sustancias residuales que pueden reutilizarse o reciclar al 100%, piezas fabricadas con plástico industrial posconsumo, entre otros.
          </p>

          <img style={styles.image} src="/img/blog/diaTierra/reforestacion.jpg" alt="Personal Requiez reforestando" srcSet=""/>

          <p style={styles.par}>
            Sabemos que el cambio no se puede dar de la noche a la mañana, pero reconocemos que el contribuir desde nuestra cancha, puede aportar a reducir el impacto climático y a sanar nuestra Madre Tierra.
          </p>

          <img style={styles.image} src="/img/blog/diaTierra/naturaleza2.jpg" alt="" srcSet=""/>

      </Fragment>
    );
  }
}

export default Note02;