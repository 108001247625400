import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import './DistributorList.scss';

class DistributorListComponent extends Component {
  render() {
    return (
      <div className="component-distributor-list mb-10">
        <Grid container spacing={1}>
          {
            this.props.distribuidores.map((item, index) => {
              return (<Grid item xs={12} sm={6} md={3} key={index}>
                <Card className="card">
                  <div className="distributor-card">
                    <div>
                      <b>{item.name}</b>
                    </div>
                    <div>
                      {item.address}
                    </div>
                    <div>
                      <a style={{ color: '#000' }} href={`tel: +52${item.phone}`} rel='noreferrer'>{item.phone}</a>
                    </div>
                    <div>
                      <a style={{ color: '#000' }} href={`mailto:${item.email}`} rel='noreferrer'>{item.email}</a>
                    </div>
                  </div>
                </Card>
              </Grid>);
            })
          }
        </Grid>
      </div>
    );
  }
}


export default DistributorListComponent;
