import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Container from '@material-ui/core/Container';
import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';

import './Alliances.scss';

class Alliances extends Component {

  render() {
    return (
      <div className="alliances-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="textPrimary">
              Alianzas
            </Typography>
          </Breadcrumbs>
          }
          bannerImage = {'categoria/alianzas.png'}
        />

        <Container maxWidth="lg" className="mb-10 text-center">
          <h2>
            Con una extensa gama de productos, con los que podrás tener un sin fin de opciones para tus proyectos.
          </h2>
          <h2 style={{color: '#646D77'}}>
            Consulta tiempos de entrega y presupuestos con tu asesor comercial.
          </h2>
          <div className="my-10 container-imgs">
            <div>
              <a href="https://www.ompgroup.com/it/" target="_blank" rel="noopener noreferrer">
                <img src="/img/alliances/omp.png" alt="alianza omp"/>
              </a>
            </div>
            <div>
              <a href="https://www.rossetto.it/" target="_blank" rel="noopener noreferrer">
                <img src="/img/alliances/rossetto.png" alt="Alianza rossetto"/>
              </a>
            </div>
            <div>
              <a href="https://www.interface.com/LA/es-MX/paginadeInicio?r=1/" target="_blank" rel="noopener noreferrer">
                <img src="/img/alliances/interface.png" alt="Alianza interface"/>
              </a>
            </div>
          </div>
        </Container>
        
        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default Alliances;