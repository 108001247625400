import { Component } from 'react';

import './ProductGallery.scss';

class ProductGallery extends Component {

  componentDidMount() {
    this.loadGallery();
  }

  loadGallery() {
    window.$('#imageGallery').lightSlider({
      gallery: true,
      item: 1,
      loop: true,
      thumbItem: 9,
      slideMargin: 0,
      currentPagerPosition: 'left',
      adaptiveHeight: true
    }); 
  }

  render() {
    return (
      <div className="product-gallery-component">
        <ul id="imageGallery">
          {this.props.imgMain.map((value, index) => {
            return <li key={index} data-thumb={`/img/products/${value.img}`} data-src={`/img/products/${value.img}`}>
                <img className="img" src={`/img/products/${value.img}`} alt={value.name}/>
              </li>
            })}
        </ul>
      </div>
    );
  }
}

export default ProductGallery;