import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link, withRouter
} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import ProductsList from './../../PagesComponents/SectionCategories/ProductsList/ProductsList';
import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';

import './Search.scss';

class Search extends Component {

  state = {
    loadView: true,
    search: null,
    categoryInfo: {},
    productsList: [],
    textiles: [],
    imgMinList: [],
    loaded: false
  };

  constructor() {
    super();
    this.setState({
      loadView: true
    });
  }

  componentDidMount() {
    this.setState({
      search: this.props.match.params.search,
      loaded: false
    });
    this.getCategoryInfoAndProducts(this.props.match.params.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        search: this.props.match.params.search,
        loaded: false
      });
      this.getCategoryInfoAndProducts(this.props.match.params.search);
    }
  }

  getCategoryInfoAndProducts(search) {
    fetch(`${global.config.URL.SERVER}/search/products/${search}`)
      .then(response => response.json())
      .then(data => this.setState({
        productsList: data.productList,
        imgList: data.imgList,
        imgMinList: data.imgMinList,
        loaded: true
      }))
      .then(() => {
        this.setState({
          loadView: false
        });
      });
  }

  render() {
    return (
      <div className="search-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="inherit">
              Buscar
            </Typography>
            <Typography color="textPrimary">{this.state.search}</Typography>
          </Breadcrumbs>
          }

          bannerImage = {'categoria/nosotros.jpg'}
        />
        { this.state.productsList && this.state.productsList.length > 0 && (
          <ProductsList
            productsList={this.state.productsList}
            imgList={this.state.imgList}
            textiles={[]}
            imgMinList={this.state.imgMinList}
            />
        )}
        { this.state.loaded && this.state.productsList && this.state.productsList.length === 0 && (
          <h1 className="text-center title-products-list mb-10">
            Productos no encontrados
          </h1>
        )}
        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(Search);