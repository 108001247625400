import { Component } from 'react';

import Container from '@material-ui/core/Container';

import './Banner.scss';

class Banner extends Component {
  render() {
    return (
      <div className="select-banner">
        <div className="image-banner" style={{ backgroundImage: `url("/img/${this.props.bannerImage}")` }}>
          <Container maxWidth="lg">
            {this.props.name &&
              <div className="title-main">
                {this.props.name}
              </div>
            }
            {this.props.inner}
          </Container>
        </div>
      </div>
    );
  }
}


export default Banner;