import { Component } from 'react';

import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';
import DistributorMap from './../../PagesComponents/SectionDistributor/DistributorMap/DistributorMap';

import './Distributor.scss';

class Distributor extends Component {
  render() {
    return (
      <div className="home-component">
        <Header/>
        <Banner
          inner={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                Inicio
              </Link>
              <Typography color="textPrimary">Distribuidores</Typography>
            </Breadcrumbs>
          }
          bannerImage = {'categoria/distribuidores.png'}
        />
        <Container maxWidth="lg">
          <h1 className="text-center mb-8">
            Busca tu distribuidor más cercano <br/> <b>Selecciona</b> tu estado o <b>Busca</b> en el mapa
          </h1>
        </Container>
        <DistributorMap/>
        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default Distributor;