import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link, withRouter
} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import ProductsList from './../../PagesComponents/SectionCategories/ProductsList/ProductsList';
import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';

import './Categories.scss';

class Categories extends Component {

  state = {
    loadView: true,
    idCategory: null,
    categoryInfo: {},
    productsList: [],
    textiles: [],
    imgList: [],
    imgMinList: []
  };

  constructor() {
    super();
    this.setState({
      loadView: true
    });
  }

  componentDidMount() {
    this.setState({
      idCategory: this.props.match.params.idCategory
    });
    this.getCategoryInfoAndProducts(this.props.match.params.idCategory);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getCategoryInfoAndProducts(this.props.match.params.idCategory);
    }
  }

  getCategoryInfoAndProducts(idCategory) {
    fetch(`${global.config.URL.SERVER}/categories/${idCategory}`)
      .then(response => response.json())
      .then(data => this.setState({ categoryInfo: data[0] }) );

    fetch(`${global.config.URL.SERVER}/categories/products/${idCategory}`)
      .then(response => response.json())
      .then(data => this.setState({
        productsList: data.productList,
        imgList: data.imgList,
        imgMinList: data.imgMinList,
        textiles: data.textiles
      }))
      .then(() => {
        this.setState({
          loadView: false
        });
      });
  }

  render() {
    return (
      <div className="categories-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="inherit">
              Productos
            </Typography>
            <Typography color="textPrimary">{this.state.categoryInfo.shortName}</Typography>
          </Breadcrumbs>
          }

          bannerImage = {this.state.categoryInfo.img}
        />
        <ProductsList
          productsList={this.state.productsList}
          imgList={this.state.imgList}
          imgMinList={this.state.imgMinList}
          textiles={this.state.textiles}
          />
        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(Categories);