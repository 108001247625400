import React, { Component } from 'react';

import './Note01.scss';

class Note01 extends Component {
  render() {
    return (
      <div className="note-component">
        <p>
          El home office pasó de ser una práctica poco común, a ser una forma de vida. Sin embargo, dentro de la denominada “nueva normalidad” no todas las empresas optarán por permanecer en el mismo esquema de trabajo.
        </p>
        <img src="/img/blog/blog feb C.jpg" alt="" srcSet=""/>
        <p>
          El home office pasó de ser una práctica poco común, a ser una forma de vida. Sin embargo, dentro de la denominada “nueva normalidad” no todas las empresas optarán por permanecer en el mismo esquema de trabajo.
        </p>
        <p>
          <b>Comodidad:</b> Referirse a la comodidad, es ir más allá de nuestra silla de trabajo, son todos los elementos que se tienen a nuestra disposición. Ya sea la mesa del comedor, la barra de cocina, el estudio o un escritorio en la recamara, es necesario que sea cual sea nuestro lugar de trabajo, aquello que se use para trabajar no sea un factor de distracción y no genere malestar con el paso de las horas, es indispensable que todo lo que se necesite para realizar nuestras jornadas se adapte al entorno.
        </p>
        <p>
          <b>Ergonomía:</b> Algunas actividades del trabajo en casa, involucran el estar sentados durante casi ocho horas, aquí la importancia de la ergonomía. Un producto ergonómico es aquel que se ajusta al cuerpo, ya sea en la postura o en los movimientos. No es cuestión de tener un respaldo acolchado o un material que solo sea cómodo. Sino de estructura y materiales hechos con el fin de ajustarse al peso, tamaño y uso del usuario. Esto no solo evita un daño en la circulación, en la espalda u otra parte del cuerpo, sino que ayuda a prevenir y corregir los problemas que se han comenzado a desarrollar.
        </p>
        <img src="/img/blog/blog feb B.jpg" alt="" srcSet=""/>
        <p>
          <b>Calidad y Diseño:</b> Son aspectos que van de la mano, un buen diseño debe estar siempre de la mano con la calidad. Los productos de calidad para cualquier ambiente de trabajo deben tener las mismas cualidades que buscarías de cualquier otro de los productos o servicios que se adquieren, dígase internet o el smartphone, requieren de ciertas especificaciones para que la vida, la convivencia con los demás e incluso el modo en el que se trabaja sean eficientes. Se busca que los productos para el home office, no solo se vean bien, sino garanticen un gran rendimiento y durabilidad.
        </p>
        <p>
          Si harás del home office un nuevo estilo de vida de ahora en adelante considera que todo aquello que usas para realizarlo, desde el equipo que adquieres, hasta los elementos que usarás en el espacio de trabajo, sean los adecuados para sacarle el máximo provecho y disfrutar tu nueva forma de emprendimiento.
        </p>
        <img src="/img/blog/blog feb C.jpg" alt="" srcSet=""/>
      </div>
    );
  }
}

export default Note01;