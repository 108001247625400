import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Container from '@material-ui/core/Container';

import './Textiles.scss';

class Textiles extends Component {

  state = {
    bottom: false
  }

  componentDidMount() {
    console.log(this.props.textiles);
  }

  render() {

    const toggleDrawer = (side, open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [side]: open });
    };

    return (
      <div className="btn-container-textile">
        <div className="btn-textile" onClick={toggleDrawer('bottom', true)} color="primary">
          <img className="textile-img" src={`/img/${this.props.textiles[0].img}`} alt=""/>
          <div className="title-name">
            Variantes <b>{this.props.textileInfo.name}</b>
          </div>
        </div>
        <SwipeableDrawer
          anchor="bottom"
          open={this.state.bottom}
          onClose={toggleDrawer('bottom', false)}
          onOpen={toggleDrawer('bottom', true)}
          className="textiles-container"
        >
          <div
            role="presentation"
            className="container-presentation"
          >
            <Container maxWidth="lg" className="pt-5 pb-2">
              <div className="container-textile-list">
                {this.props.textiles.map((value, index) => {
                  return <div className="btn-textile" color="primary" key={index}>
                    <img className="textile-img" src={`/img/${value.img}`} alt=""/>
                    <div className="title-name">
                      {value.name}
                    </div>
                  </div>
                })}
                
              </div>
            </Container>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default Textiles;