import React from 'react';

import './SectionCatalogue.scss';

export default function SectionCatalogue() {
  return (
    <div className="section-catalogue">
      <div className="container-2-columns">
        <div className="container-img">
          <img src="/img/catalogo.jpeg" alt="Catalogo"/>
        </div>
        <div>
          <div className="container-info-center">
            <div>
              <h2>
                NUESTRA LÍNEA DEL <br/> CATÁLOGO 2023
              </h2>
              <div>
                <p>
                  Encuentra la solución adecuada para cada espacio y necesidad con el máximo confort y calidad.
                </p>
                <p>
                  Diseño, tecnología y eficiencia en cada uno de nuestros productos.
                </p>
              </div>
              <div className="mt-10">
                <a target="_blank" href="/files/Catalogo.pdf" className="btn">
                  Descubre Más
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}